<template>
  <section class="overview-block-ptb iq-bg iq-bg-fixed iq-over-black-80-banner" style="background: url(assets/images/wasteup/about/04.jpg);" >
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="heading-title iq-mb-20" style="margin-bottom: 30px">
                        <h2 class="title iq-tw-6 iq-font-white">{{ $t('newsletter.title') }}</h2>
                        <div class="divider white"></div>
                        <p class="iq-font-white" style="margin-bottom: 0">{{ $t('newsletter.p1') }}</p>
                        <p class="iq-font-white" style="margin-bottom: 0">{{ $t('newsletter.p2') }}</p>
                        <p class="iq-font-white" style="margin-bottom: 0">{{ $t('newsletter.p3') }}</p>
                        <p class="iq-font-white" style="margin-bottom: 0">{{ $t('newsletter.p4') }}</p>
                        <p class="iq-font-white" style="margin-bottom: 0">{{ $t('newsletter.p5') }}</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 text-center">
                    <a class="button button-icon re7-mt-30 re4-mt-20" href="mailto:info@carbonaddons.id"> <i class="ion-email"></i> {{ $t("newsletter.btnConnect") }}</a>
                </div>
        </div>
        </div>
    </section>
</template>


<script>
export default {
  name: "Header",
  components: {},
  data() {
  },
  methods: {
  },
};
</script>