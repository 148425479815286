<template>
  <div>
    <Header></Header>
    <Banner></Banner>
    <Feature></Feature>
    <hr />
    <About></About>
    <Features></Features>
    <Screen></Screen>
    <GreatFeatures></GreatFeatures>
    <hr />
    <!-- <Team></Team> -->
    <Counter></Counter>
    <Faq></Faq>
    <Partnership></Partnership>
    <Newsletter></Newsletter>
    <Blog></Blog>
    <div class="footer">
      <Map></Map>
      <Contact></Contact>
      <Footer></Footer>
    </div>
  </div>
</template>
<script>
import Header from "@/components/wasteup/Header.vue";
import Banner from "@/components/wasteup/Banner.vue";
import Feature from "@/components/wasteup/Feature.vue";
import About from "@/components/wasteup/About.vue";
import Features from "@/components/wasteup/App_features.vue";
import Screen from "@/components/wasteup/App_screenshot.vue";
import GreatFeatures from "@/components/wasteup/App_great_features.vue";
// import Testimoni from "@/components/wasteup/App_testimoni.vue";
// import Team from "@/components/wasteup/Team.vue";
import Counter from "@/components/wasteup/App_counter.vue";
import Faq from "@/components/wasteup/App_faq.vue";
import Partnership from "@/components/wasteup/Partnership.vue";
import Newsletter from "@/components/wasteup/Newsletter.vue";
// import Pricing from "@/components/wasteup/Pricing.vue";
import Blog from "@/components/wasteup/Blog.vue";
import Map from "@/components/wasteup/App_map.vue";
import Contact from "@/components/wasteup/Contact.vue";
import Footer from "@/components/wasteup/Footer.vue";

export default {
  name: "Wasteup",
  components: {
    Header,
    Banner,
    Feature,
    About,
    Features,
    Screen,
    GreatFeatures,
    // Testimoni,
    // Team,
    Counter,
    Faq,
    Partnership,
    // Pricing,
    Newsletter,
    Blog,
    Map,
    Contact,
    Footer,
  },
  mounted() {
    document.title = "Surabaya Wasteup Project";
    this.$gtag.pageview(window.location.href);
  },
};
</script>
