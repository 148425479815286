<template>
  <section class="overview-block-pt white-bg" id="faq" >
    <div class="container">
      <div class="row">
          <div class="col-sm-12">
              <div class="heading-title">
                  <h2 class="title iq-tw-6">{{ $t('faq.title') }}</h2>
                  <div class="divider"></div>
                  <p>{{ $t('faq.subtitle') }}</p>
              </div>
          </div>
      </div>
      <div class="row">
          <div class="col-sm-12 col-md-6">
            <h6 class="iq-tw-6 iq-font-black iq-pb-20 lead">{{ $t('faq.points.title') }}</h6>
              <p>{{ $t('faq.points.subtitle') }}<br><br>{{ $t('faq.points.description') }}</p>
          </div>
          <div class="col-sm-12 col-md-6 re-mt-30">
              <div class="iq-accordion">
                  <div class="iq-ad-block ad-active"> <a href="javascript:void(0)" class="ad-title iq-tw-6 iq-font-grey"> <span class="ad-icon"><i class="ion-ios-infinite-outline" aria-hidden="true"></i></span>{{ $t('faq.points.faq1.title') }}</a>
                  <div class="ad-details">
                  <div class="row">
                    <div class="col-sm-3"><img alt="#" class="img-responsive" src="assets/images/wasteup/faq-1.jpg"></div>
                    <div class="col-sm-9"> {{ $t('faq.points.faq1.subtitle') }}</div>   
                  </div>
                  </div>
                  </div>

                  <div class="iq-ad-block"> <a href="javascript:void(0)" class="ad-title iq-tw-6 iq-font-grey"> <span class="ad-icon"><i class="ion-star" aria-hidden="true"></i></span>{{ $t('faq.points.faq2.title') }}</a>
                      <div class="ad-details">{{ $t('faq.points.faq2.subtitle') }}</div>
                  </div>
                  <div class="iq-ad-block"> <a href="javascript:void(0)" class="ad-title iq-tw-6 iq-font-grey"> <span class="ad-icon"><i class="ion-person-stalker" aria-hidden="true"></i></span>{{ $t('faq.points.faq3.title') }}</a>
                      <div class="ad-details">
                        <div class="row">
                          <div class="col-sm-9"> {{ $t('faq.points.faq3.subtitle') }}</div>  
                    <div class="col-sm-3"><img alt="#" class="img-responsive" src="assets/images/wasteup/faq-2.jpg"></div>
                        </div>
                      </div>
                  </div>
                  <div class="iq-ad-block"> <a href="javascript:void(0)" class="ad-title iq-tw-6 iq-font-grey"> <span class="ad-icon"><i class="ion-heart" aria-hidden="true"></i></span>{{ $t('faq.points.faq4.title') }}</a>
                      <div class="ad-details">{{ $t('faq.points.faq4.subtitle') }}</div>
                  </div>
              </div>
          </div>
      </div>
    </div>
  </section>
</template>

 <script>
import $ from "jquery";
export default {
  components: {},
  mounted() {
    $(".iq-accordion .iq-ad-block .ad-details").hide();
    $(".iq-accordion .iq-ad-block:first")
      .addClass("ad-active")
      .children()
      .slideDown("slow");
    $(".iq-accordion .iq-ad-block").on("click", function () {
      if ($(this).children("div").is(":hidden")) {
        $(".iq-accordion .iq-ad-block")
          .removeClass("ad-active")
          .children("div")
          .slideUp("slow");
        $(this).toggleClass("ad-active").children("div").slideDown("slow");
      }
    });
  },
};
</script>
