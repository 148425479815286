<template>
  <section id="hubungi" class="iq-our-touch">
      <div class="container">
      <div class="iq-get-in iq-pall-40 white-bg">
          <div class="row">
              <div class="col-sm-12 col-md-6 col-lg-6">
                      <h4 class="heading-left iq-tw-6 iq-pb-20 iq-mb-40">{{ $t("getInTouch.title") }}</h4>
                          <div id="formmessage">Success/Error Message Goes Here</div>
                          <form class="form-horizontal" id="contactform" method="post" action="#">
                              <div class="contact-form">
                                  
                                      <div class="section-field">
                                          <input id="name" type="text" :placeholder="$t('getInTouch.form.name')" name="name">
                                      </div>
                                      <div class="section-field">
                                          <input type="email" :placeholder="$t('getInTouch.form.email')" name="email">
                                      </div>
                                      <div class="section-field">
                                          <input type="text" :placeholder="$t('getInTouch.form.phone')" name="phone">
                                      </div>
                                  

                                      <div class="section-field textarea">
                                          <textarea class="input-message" :placeholder="$t('getInTouch.form.comment')" rows="6" name="message"></textarea>
                                      </div>
                                      <input type="hidden" name="action" value="sendEmail" />
                                      <button id="submit" name="submit" type="submit" value="Send" class="button iq-mt-30" disabled>{{ $t('getInTouch.form.btnSubmit') }}</button>

                              </div>
                          </form>
                          <div id="ajaxloader" style="display:none"><img class="center-block mt-30 mb-30" src="images/ajax-loader.gif" alt="#"></div>
                  </div>
                  <div class="col-sm-12 col-md-6 col-lg-6 re-mt-30">
                      <div class="contact-info iq-pall-60 iq-pt-0">
                          <!-- <h4 class="heading-left iq-tw-6 iq-pb-20 iq-mb-40"></h4> -->
                      <div class="iq-fancy-box-02">
                      <div class="iq-icon green-bg">
                          <i aria-hidden="true" class="ion-ios-location-outline"></i>
                      </div>
                      <div class="fancy-content">
                          <h5 class="iq-tw-6">{{ $t("getInTouch.contactUs.address.title") }}</h5>
                          <span class="lead iq-tw-6">{{ $t("getInTouch.contactUs.address.subtitle") }}</span>
                      </div>
                  </div>
                  <div class="iq-fancy-box-02 iq-mt-40">
                      <div class="iq-icon green-bg">
                          <i aria-hidden="true" class="ion-ios-telephone-outline"></i>
                      </div>
                      <div class="fancy-content">
                          <h5 class="iq-tw-6">{{ $t("getInTouch.contactUs.phone.title") }}</h5>
                          <span class="lead iq-tw-6">{{ $t("getInTouch.contactUs.phone.subtitle") }}</span>
                          <p class="iq-mb-0">{{ $t("getInTouch.contactUs.phone.description") }}</p>
                      </div>
                  </div>
                  <div class="iq-fancy-box-02 iq-mt-40">
                      <div class="iq-icon green-bg">
                          <i aria-hidden="true" class="ion-ios-email-outline"></i>
                      </div>
                      <div class="fancy-content">
                          <h5 class="iq-tw-6">{{ $t("getInTouch.contactUs.mail.title") }}</h5>
                          <span class="lead iq-tw-6">{{ $t("getInTouch.contactUs.mail.subtitle") }}</span>
                          <p class="iq-mb-0">{{ $t("getInTouch.contactUs.mail.description") }}</p>
                      </div>
                  </div>
                  <ul class="info-share">
                      <li><a href="https://www.linkedin.com/company/carbon-addons/" target="_blank"><i class="fa fa-linkedin"></i></a></li>
                      <li><a href="https://instagram.com/carbonacademy.id" target="_blank"><i class="fa fa-instagram"></i></a></li>
                  </ul>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
</template>

<script>
export default {
  name: "Header",
  components: {},
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        name: "",
        email: "",
        phone: "",
        comment: "",
        alertMessage: "",
        alertType: "",
        path: "/api/home-page/contact-us",
      };
    },
    resetState() {
      Object.assign(this.$data, this.initialState());
    },
    sendEvent() {
      this.alertMessage = "";
      this.alertType = "";

      if (this.name === "") {
        this.alertMessage = "name is required";
        this.alertType = "alert-danger";
      }

      if (this.email === "") {
        this.alertMessage = "email is required";
        this.alertType = "alert-danger";
      }

      if (this.phone === "") {
        this.alertMessage = "phone is required";
        this.alertType = "alert-danger";
      }

      if (this.comment === "") {
        this.alertMessage = "comment is required";
        this.alertType = "alert-danger";
      }

      if (this.alertMessage === "") {
        this.axios
          .post(this.$hostApi + this.path, {
            name: this.name,
            email: this.email,
            phone: this.phone,
            comment: this.comment,
          })
          .then((response) => {
            this.resetState();
            this.alertMessage = response.data;
            this.alertType = "alert-success";
          })
          .catch(function (error) {
            console.log(error.response);
            alert(error.response.statusText);
          });
      }
    },
    closeAlertEvent() {
      this.alertMessage = "";
      this.alertType = "";
    },
  },
};
</script>