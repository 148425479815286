<template>
  <header id="header-wrap" data-spy="affix" data-offset-top="55">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <nav class="navbar navbar-default">
            <!-- Brand and toggle get grouped for better mobile display -->
            <div class="navbar-header">
              <button
                type="button"
                class="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#bs-example-navbar-collapse-1"
                aria-expanded="false"
              >
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
              </button>
              <a class="navbar-brand" href="javascript:void(0)">
                <img src="assets/images/logo-wasteup.png" alt="logo" />
              </a>
            </div>
            <!-- Collect the nav links, forms, and other content for toggling -->
            <div
              class="collapse navbar-collapse"
              id="bs-example-navbar-collapse-1"
            >
              <ul class="nav navbar-nav navbar-right" id="top-menu">
                <li class="active" @click="clickEvent()">
                  <a
                    href="#iq-home"
                    @click="clickEvent('click', 'Home')"
                    v-smooth-scroll
                    >{{ $t("header.home") }}</a
                  >
                </li>
                <li>
                  <a
                    href="#tentang"
                    @click="clickEvent('click', 'tentang')"
                    v-smooth-scroll
                    >{{ $t("header.about") }}</a
                  >
                </li>
                <li>
                  <a
                    href="#solusi"
                    @click="clickEvent('click', 'solusi')"
                    v-smooth-scroll
                    >{{ $t("header.solution") }}</a
                  >
                </li>
                <li>
                  <a
                    href="#syarat"
                    @click="clickEvent('click', 'syarat')"
                    v-smooth-scroll
                    >{{ $t("header.apply") }}</a
                  >
                </li>
                <li>
                  <a
                    href="#linimasa"
                    @click="clickEvent('click', 'linimasa')"
                    v-smooth-scroll
                    >{{ $t("header.timeline") }}</a
                  >
                </li>
                <li>
                  <a
                    href="#faq"
                    @click="clickEvent('click', 'faq')"
                    v-smooth-scroll
                    >{{ $t("header.faq") }}</a
                  >
                </li>
                <li>
                  <a
                    href="#hubungi"
                    @click="clickEvent('click', 'hubungi')"
                    v-smooth-scroll
                    >{{ $t("header.contact") }}</a
                  >
                </li>
                <li>
                  <div class="dropdown">
                    <button class="btn btn-default dropdown-toggle" type="button" id="dropdownMenu1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                      🌐 {{ $i18n.locale }}
                      <span class="caret"></span>
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenu1">
                      <li @click="setLocale('id')" ><a> <img class="fa-fw me-2" src="assets/images/flags/id.svg" alt=""> Bahasa Indonesia</a></li>
                      <li @click="setLocale('en')"><a> <img class="fa-fw me-2" src="assets/images/flags/uk.svg" alt=""> English</a></li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
            <!-- /.navbar-collapse -->
          </nav>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "Header",
  components: {},
  methods: {
    clickEvent(action, homepageSection) {
      this.$gtag.event(action, {
        homepage_section: homepageSection,
      });
    },
    setLocale(lang){
      this.$i18n.locale = lang;
    }
  },
};
</script>
