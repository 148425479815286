<template>
  <div
    class="iq-ptb-70 iq-counter-box iq-bg iq-bg-fixed iq-over-black-80-banner"
    style="background: url(assets/images/wasteup/about/04.jpg)"
  >
    <div class="counter text-center">
      <label
        class="iq-font-white"
        style="margin-bottom: 60px; font-size: 3em"
        >{{ $t("counter.title") }}</label
      >
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-sm-3 col-xs-6 text-center">
          <div class="counter">
            <i
              class="ion-ios-folder-outline iq-font-white"
              aria-hidden="true"
            ></i>
            <br />
            <!-- <span class="timer iq-tw-6 iq-font-black" data-to="1540" data-speed="10000" style="color: #4eb7ba">1540</span> -->
            <label class="iq-font-white"
              ># {{ $t("counter.entrepreneurs") }}</label
            >
          </div>
        </div>
        <div class="col-lg-3 col-sm-3 col-xs-6 text-center">
          <div class="counter">
            <i
              class="ion-ios-paper-outline iq-font-white"
              aria-hidden="true"
            ></i>
            <br />
            <!-- <span class="timer iq-tw-6 iq-font-black" data-to="2530" data-speed="10000" style="color: #4eb7ba">2530</span> -->
            <label class="iq-font-white"
              ># {{ $t("counter.jobsCreated") }}</label
            >
          </div>
        </div>
        <div class="col-lg-3 col-sm-3 col-xs-6 text-center re7-mt-50">
          <div class="counter">
            <i
              class="ion-ios-person-outline iq-font-white"
              aria-hidden="true"
            ></i>
            <br />
            <!-- <span class="timer iq-tw-6 iq-font-black" data-to="8120" data-speed="10000" style="color: #4eb7ba">8120</span> -->
            <label class="iq-font-white"
              ># {{ $t("counter.jobSupported") }}</label
            >
          </div>
        </div>
        <div class="col-lg-3 col-sm-3 col-xs-6 text-center re7-mt-50">
          <div class="counter">
            <i class="ion-ios-star iq-font-white" aria-hidden="true"></i> <br />
            <!-- <span class="timer iq-tw-6 iq-font-black" data-to="1620" data-speed="10000" style="color: #4eb7ba">1620</span> -->
            <label class="iq-font-white"
              ># {{ $t("counter.investment") }}</label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "App_Counter",
  components: {},
  data() {
    return {};
  },
};
</script>