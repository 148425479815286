<template>
<section id="syarat" class="overview-block-ptb iq-bg iq-bg-fixed iq-over-black-80-banner iq-screenshots" style=" background: url(assets/images/wasteup/banner/bg.jpg);">
  <div class="container">
      <div class="row">
          <div class="col-sm-12">
              <div class="heading-title" style="margin-bttom: 0">
                  <h2 class="title iq-tw-6 iq-font-white">{{ $t('canApply.title') }}</h2>
                  <div class="divider white"></div>
                  <p class="iq-font-white" style="margin-bottom: 0">{{ $t('canApply.subtitle') }}</p>
                  <p class="iq-font-white">{{ $t('canApply.criteria.title') }}</p>
              </div>
          </div>
      </div>
      <div class="row" style="margin: 0 0;">
        <div class="col-lg-4 col-md-4 col-sm-12" style="margin: 20px 0;">
            <div class="iq-pricing text-left" style="border-bottom: 6px solid rgba(78, 183, 186); padding: 10px">
              <p>{{ $t('canApply.criteria.points.p1') }}</p><br>
            </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12" style="margin: 20px 0;">
            <div class="iq-pricing text-left" style="border-bottom: 6px solid rgba(78, 183, 186); padding: 10px;">
              <p>{{ $t('canApply.criteria.points.p2') }}</p><br><br><br>
            </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12" style="margin-top: 20px">
            <div class="iq-pricing text-left" style="border-bottom: 6px solid rgba(78, 183, 186); padding: 10px">
              <p>{{ $t('canApply.criteria.points.p3') }}</p>
              <pre style="border: 0; background-color: transparent;">{{ $t('canApply.criteria.points.br') }}</pre>
            </div>
        </div>
      </div>
      <div class="row" style="margin: 0">
        <div class="col-lg-2 col-md-2 col-sm-12 hidden-sm"></div>
        <div class="col-lg-4 col-md-4 col-sm-12" style="margin: 20px 0;">
            <div class="iq-pricing text-left" style="border-bottom: 6px solid rgba(78, 183, 186); padding: 10px">
              <p>{{ $t('canApply.criteria.points.p4') }}</p><br><br>
            </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12" style="margin: 20px 0;">
            <div class="iq-pricing text-left" style="border-bottom: 6px solid rgba(78, 183, 186); padding: 10px">
              <p>{{ $t('canApply.criteria.points.p5') }}</p><br><br>
            </div>
        </div>
        <div class="col-lg-2 col-md-2 col-sm-12 hidden-sm"> </div>
      </div>
  </div>
</section>
  
</template>

<script>
// import carousel from "vue-owl-carousel";
export default {
  components: {
    // carousel,
  },
  mounted() {},
};
</script>