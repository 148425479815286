<template>
  <section id="about-us" class="overview-block-ptb iq-about-1 grey-bg">
        <div class="container">
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <img class="img-responsive center-block wow fadeInLeft" data-wow-duration="1s" src="assets/images/wasteup/01.png" alt="#">
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <h2 class="heading-left iq-tw-6 iq-mt-40">{{ $t('about.title') }}</h2>
                    <div class="lead iq-tw-6 iq-mb-20">{{ $t('about.subtitle') }}</div>
                    <p>{{ $t('about.description') }}</p>
                    <a class="button iq-mt-25" href="https://bit.ly/BookletSWUP" target="_blank">{{ $t('about.btnReadmore') }}</a>
                </div>
            </div>
        </div>
    </section>
</template>


<script>
export default {
  name: "About",
  components: {},
};
</script>