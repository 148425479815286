<template>
  <section id="blog" class="overview-block-ptb white-bg iq-blog">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="heading-title">
            <h2 class="title iq-tw-6">Latest Blog Post</h2>
            <div class="divider"></div>
            <p>
              Our blog is your platform for insights, tips, and stories on
              everything from circular economy principles to local success
              stories.
            </p>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-sm-12 col-md-4 col-lg-4"
          v-for="(blog, index) in blogs.slice(0, 3)"
          :key="index"
        >
          <div class="iq-blog-box">
            <div class="iq-blog-image clearfix">
              <img
                class="img-responsive center-block"
                :src="blog.image_url"
                alt="#"
              />
            </div>
            <div class="iq-blog-detail">
              <div class="blog-title">
                <a :href="blog.link"
                  ><h5 class="iq-tw-6">{{ blog.title }}</h5>
                </a>
              </div>
              <div class="iq-blog-meta">
                <ul class="list-inline">
                  <li>
                    <a href="javascript:void(0)"
                      ><i class="fa fa-calendar" aria-hidden="true"></i>
                      {{ blog.created_at }}</a
                    >
                  </li>
                  <li>
                    <!-- <i class="fa fa-comment-o" aria-hidden="true"></i>
                    <span v-html="blog.comment_count"></span> -->
                  </li>
                </ul>
              </div>
              <div class="blog-content">
                <p>
                  {{ blog.short_desc }}
                </p>
              </div>
              <div class="blog-button">
                <a href="javascript:void(0)" class="pull-left iq-tw-6 iq-user"
                  ><i class="fa fa-user-circle" aria-hidden="true"></i>
                  {{ blog.created_by }}</a
                >
                <a :href="blog.link" class="pull-right iq-tw-6"
                  >Read More <i class="fa fa-angle-right" aria-hidden="true"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 text-center">
          <a
            class="button iq-mt-50"
            href="https://blog.carbonaddons.id"
            target="_blank"
            >Go To Blog</a
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Blog",
  components: {},
  data() {
    return {
      blogs: [],
      path: "/api/WasteUp Project",
    };
  },
  mounted() {
    this.axios
      .get(this.$blogApi + this.path)
      .then((res) => {
        let data = res.data;
        if (data.status == "ok") {
          this.blogs = data.data;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
};
</script>