<template>
<section class="overview-block-ptb white-bg" id="partnerships">
  <div class="container">
    <div class="row">
        <div class="col-sm-12">
            <div class="heading-title">
                <h2 class="title iq-tw-6">{{ $t('partnerships.title') }}</h2>
                <div class="divider"></div>
                <p>{{ $t('partnerships.subtitle') }}</p>
            </div>
        </div>
    </div>
    <div class="row">
      <div class="col-12">
          <vue-glide 
            :type="carousel" 
            :perView="6" 
            :autoplay="4000" 
            :gap="30" 
            :breakpoints="{ 800: { perView: 1 } }"
            >
            <vue-glide-slide v-for="(partner, index) in partnerships" :key="index">
              <div class="div-image" :style="getBackgroundImageUrl(partner.path)"></div>
            </vue-glide-slide>
          </vue-glide>
      </div>
    </div>
  </div>
</section>
</template>
<style>
  .div-image{
    background-size: contain;
    background-repeat: no-repeat; 
    background-position: center center;
    height: 100px; 
    width: auto;
  }
</style>
<script>
import { Glide, GlideSlide } from 'vue-glide-js';
import 'vue-glide-js/dist/vue-glide.css';
export default {
  name: 'partnership',
  components: {
    [Glide.name]: Glide,
    [GlideSlide.name]: GlideSlide,
  },
  data(){
      return {
        partnerships: [
          {path: "koridor HQ-hitam.png"},
          {path: "Logo Pemkot Surabaya.png"},
          {path: "LOGO PF COLOR.png"},
          {path: "sparkling-surabaya-HQ.png"},
          {path: "Ancora-Foundation-logo.png"},
          {path: "Brand Guidelines ANGIN v2 (1)_Page1_Image2.png"},
          {path: "BELANTARA LOGO VEKTOR EN.png"},
          {path: "BW Kehati.png"},
          {path: "FA LOGO PACKAGE CLEANOMIC (NEW)-01 (1) (2).png"},
          {path: "ciputra logo.png"},
          {path: "CSWM UI - Logo - UI.jpg"},
          {path: "Ecoxyztem logo.png"},
          {path: "c4544215-c4ed-4e29-be70-4ddd5271a124.png"},
          {path: "LOGO GOODSIDE_SQUARE.png"},
          {path: "LOGO GO FORWARD.png"},
          {path: "Branding LOGO UKWMS-01.png"},
          {path: "Logo Kawan GNFI.png"},
          {path: "Logo_KUMPUL v.1.png"},
          {path: "Logo Lestari_Black.png"},
          {path: "IMG_7787.PNG"},
          {path: "2) PLUS w_ Platform Usaha Sosial Transparant (2).png"},
          {path: "Seabolga black.png"},
          {path: "Copy of LOGO GENERAL-01.png"},
          {path: "WCDI logo.png"},
        ]
      }
  },
  methods: {
    getBackgroundImageUrl(url){
      return "background-image:url('assets/images/wasteup/partnerships/"+url+"')";
    }
  },
}
</script>
